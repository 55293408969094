<template>
  <div>
    <div class="container my-5">
      <div class="row">
        <div
          class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-md-2"
        >
          <div class="card shadow">
            <div class="card-body p-5">
              <h3 class="cl-primary f-bold text-center">
                Mimo <span class="cl-secondary"> Register</span>
              </h3>
              <form class="mt-4" @submit.prevent="register">
                <div class="row">
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="text"
                      class="form-control b-radius px-4"
                      id="name"
                      placeholder="Name"
                      v-model="form.name"
                      required
                    />
                  </div>
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="email"
                      class="form-control b-radius px-4"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      v-model="form.email"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="number"
                      class="form-control b-radius px-4"
                      id="phone"
                      aria-describedby="emailHelp"
                      placeholder="Phone Number"
                      v-model="form.telephone"
                      required
                    />
                  </div>
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <select
                      class="form-control b-radius px-3"
                      id="gender"
                      v-model="form.gender"
                      required
                    >
                      <option selected disabled>Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <div class="form-group">
                      <!-- <label for="exampleFormControlTextarea1">Example textarea</label> -->
                      <textarea
                        class="form-control b-radius px-3"
                        id="address"
                        rows="3"
                        placeholder="Address "
                        v-model="form.address"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="text"
                      class="form-control b-radius px-4"
                      id="username"
                      aria-describedby="emailHelp"
                      placeholder="Username"
                      v-model="form.username"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="password"
                      class="form-control b-radius px-4"
                      id="password"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                      v-model="form.password"
                      required
                    />
                  </div>
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                  >
                    <input
                      type="password"
                      class="form-control b-radius px-4"
                      id="password_confirmation"
                      aria-describedby="emailHelp"
                      placeholder="Confirm Password"
                      v-model="form.password_confirmation"
                      required
                    />
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-4" v-if="!loading">
                  <button type="submit" class="btn btn-success px-5">
                    Register
                  </button>
                </div>
                <div class="d-flex justify-content-center mt-4" v-if="loading">
                  <loader-component></loader-component>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  name: "Register",
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        telephone: "",
        gender: "",
        address: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    register: function () {
      this.loading = true;
      let data = {
        name: this.form.name,
        role: "customers",
        username: this.form.username,
        email: this.form.email,
        telephone: this.form.telephone,
        gender: this.form.gender,
        address: this.form.address,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      };
      this.$store
        .dispatch("register", data)
        .then((res) => {
          this.loading = false;
          console.log(res);
          Vue.swal(res.data.status, res.data.message, "success");
          this.$router.push("/login");
        })
        .catch((err) => {
          this.loading = false;
          let errorMsg = "";
          Object.keys(err.response.data).forEach(function (key) {
            let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
            let errorCause = "";
            let errorLists = err.response.data[key];
            for (var prop in errorLists) {
              errorCause += "<li>" + errorLists[prop] + "</li>";
            }
            errorMsg +=
              "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
              errorItem +
              "</b>" +
              errorCause +
              "</ul><br>";
          });

          Vue.swal({
            icon: "error",
            title: "Error!",
            html: errorMsg,
          });
          console.log(err.response.data);
        });
    },
  },
};
</script>
